import { Injectable } from '@angular/core';
import { mapAssignedAssignmentResponseToAssignedAssignment } from '@app/assignments-assigned/assignments-assigned.mappers';
import { AssignedAssignment } from '@app/assignments-assigned/interfaces/assigned-assignment.interface';
import { AssigneeType } from '@app/assignments-managed/assignments-managed.enums';
import {
  mapAssignContentDataToAssignContentPostData,
  mapManagedAssignmentRequestDataToManagedAssignment,
} from '@app/assignments-managed/assignments-managed.mappers';
import { AssignmentContextMeta } from '@app/assignments-managed/interfaces/assignment-context-meta.interface';
import { AssignmentItemDetails } from '@app/assignments-managed/interfaces/assignment-item-details.interface';
import { AssignContentData } from '@app/assignments-managed/interfaces/assignments-managed.interfaces';
import { EditAssignmentRules } from '@app/assignments-managed/interfaces/edit-assignment-rules.interface';
import {
  ManagedAssignment,
  ManagedAssignmentRequestData,
} from '@app/assignments-managed/interfaces/managed-assignment.interface';
import { Cart } from '@app/cart/interfaces/cart.interface';
import { CartItemRequest } from '@app/cart/interfaces/cart-item-request.interface';
import { ContentType } from '@app/content/enums/content-type.enum';
import { Printable } from '@app/content/interfaces/printable.interface';
import {
  AssignmentStatus,
  AssignmentStatusResponse,
  AssignmentStudentOverview,
} from '@app/statistics-teacher/interfaces/statistics-teacher-assignment-status.interface';
import { mapAssignmentStatusResponseToAssignmentStatus } from '@core/mappers/assignment-status.mappers';
import { TranslateService } from '@ngx-translate/core';
import { GUID } from '@shared-types/guid.type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class HomeworkApiService {
  constructor(
    private api: ApiService,
    private translateService: TranslateService,
  ) {}

  public fetchCart(): Observable<Cart> {
    return this.api.getBFFGateway('v2/carts').pipe(map((response) => (<{ result: Cart }>response).result));
  }

  public emptyCart(): Observable<void> {
    return this.api.deleteBFFGateway('v2/carts/clear');
  }

  public addItem(item: CartItemRequest): Observable<Cart> {
    return this.api.postBFFGateway<{ result: Cart }>('v2/carts/add', item).pipe(map((response) => response.result));
  }

  public removeItem(itemId: GUID): Observable<Cart> {
    return this.api
      .postBFFGateway<{ result: Cart }>('v2/carts/remove', { itemId })
      .pipe(map((response) => response.result));
  }

  assignmentsGetAllAssignmentsAssignedToUser(): Observable<AssignedAssignment[]> {
    return this.api
      .getBFFGateway('v1/assignments/assigned?includeInactive=true')
      .pipe(
        map((response) =>
          (<{ result: AssignedAssignment[] }>response).result.map(mapAssignedAssignmentResponseToAssignedAssignment),
        ),
      );
  }

  assignmentsGetAssignmentByIdAssignedToUser(assignmentId: GUID): Observable<AssignedAssignment> {
    return this.api
      .getBFFGateway(`v1/assignments/${assignmentId}/assigned`)
      .pipe(
        map((response) =>
          mapAssignedAssignmentResponseToAssignedAssignment((<{ result: AssignedAssignment }>response).result),
        ),
      );
  }

  assignmentsStartAssignedAssignmentItem(assignmentId: GUID, assignmentItemId: GUID): Observable<AssignedAssignment> {
    return this.api
      .postBFFGateway(`v1/assignments/${assignmentId}/assignment-item/${assignmentItemId}/start`)
      .pipe(map((response) => (<{ result: AssignedAssignment }>response).result));
  }

  assignmentsGetAssignedAssignmentsOverview(
    assigneeType?: AssigneeType,
    assigneeId?: GUID | null,
  ): Observable<AssignmentStatus[]> {
    let body = {};

    if (assigneeType && assigneeId) {
      body = {
        assigneeId,
        assigneeType,
      };
    }
    return this.api
      .getBFFGateway<{ result: AssignmentStatusResponse[] }>(
        `v1/assignments/assigned/overview?${new URLSearchParams(body).toString()}`,
      )
      .pipe(map(({ result }) => result.map(mapAssignmentStatusResponseToAssignmentStatus)));
  }

  public assignmentsGetAssignmentById(id: GUID): Observable<ManagedAssignment> {
    return this.api
      .getBFFGateway(`v1/assignments/${id}`)
      .pipe(
        map((response) =>
          mapManagedAssignmentRequestDataToManagedAssignment(
            (<{ result: ManagedAssignmentRequestData }>response).result,
          ),
        ),
      );
  }

  public getAssignmentForEditingById(
    assignmentId: GUID,
  ): Observable<{ assignment: ManagedAssignment; editAssignmentRules: EditAssignmentRules }> {
    return this.api
      .getBFFGateway<{ result: ManagedAssignmentRequestData; meta: { editAssignmentRules: EditAssignmentRules } }>(
        `v1/assignments/${assignmentId}/edit`,
      )
      .pipe(
        map((response) => ({
          assignment: mapManagedAssignmentRequestDataToManagedAssignment(response.result),
          editAssignmentRules: response.meta.editAssignmentRules,
        })),
      );
  }

  /**
   * Assigns the given content to the given team.
   */
  public assignmentsCreateAssignment(data: AssignContentData): Observable<GUID> {
    return this.api
      .postBFFGateway('v1/assignments', mapAssignContentDataToAssignContentPostData(data))
      .pipe(map((response) => (<{ result: GUID }>response).result));
  }

  public assignmentsUpdateAssignment(assignmentId: GUID, data: AssignContentData): Observable<GUID> {
    return this.api.putBFFGateway(`v1/assignments/${assignmentId}`, mapAssignContentDataToAssignContentPostData(data));
  }

  public deleteAssignment(assignmentId: GUID): Observable<any> {
    return this.api.deleteBFFGateway(`v1/assignments/${assignmentId}`);
  }

  public getAssignmentStatus(assignmentId: GUID): Observable<AssignmentStudentOverview> {
    return this.api
      .getBFFGateway(`v1/assignments/${assignmentId}/assigned/student-overview`)
      .pipe(map((response) => (<{ result: AssignmentStudentOverview }>response).result));
  }

  /**
   * Loads an assignment item including metadata used for creating breadcrumbs.
   * Only supports printable content (for now).
   */
  public loadAssignmentItem(assignmentItemId: GUID): Observable<AssignmentItemDetails> {
    return this.api
      .getBFFGateway<{
        result: Printable & { $type: ContentType.ACTIVITY | ContentType.WORKSHEET };
        meta: { assignment: AssignmentContextMeta; assignmentItem: AssignmentContextMeta };
      }>(`v1/assignments/assignment-item/${assignmentItemId}`)
      .pipe(
        map((response) => ({
          printable: response.result,
          contentType: response.result.$type,
          assignmentContext: response.meta.assignment,
          assignmentItemContext: response.meta.assignmentItem,
        })),
      );
  }

  /*
 Copy assignment
  */

  public getCopyAssignment(assignmentId: GUID): Observable<ManagedAssignmentRequestData> {
    return this.api
      .getBFFGateway(`v1/assignments/${assignmentId}/copy`)
      .pipe(map((response) => (<{ result: ManagedAssignmentRequestData }>response).result));
  }

  public assignmentsCreateCopiedAssignment(data: AssignContentData): Observable<GUID> {
    return this.api
      .postBFFGateway('v1/assignments/copy', mapAssignContentDataToAssignContentPostData(data))
      .pipe(map((response) => (<{ result: GUID }>response).result));
  }
}
